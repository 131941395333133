import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Button, Dialog, DialogActions, DialogContent, TextField,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Checkbox, FormControlLabel, FormGroup, Select, MenuItem, InputAdornment, Zoom,
  CircularProgress, TablePagination, TableSortLabel
} from '@mui/material';
import { styled } from '@mui/system';
import Swal from 'sweetalert2';
import { Search } from '@mui/icons-material';
import axios from 'axios';
import { apilinkmain } from '../api';

const themeColor = {
  primary: '#444',
  primaryDark: '#666',
  success: '#4caf50',
  error: '#f44336',
  headerBg: '#ffff',
  headerTextColor: '#ffffff',
  borderColor: '#777',
  color: '#000000',
  rowHoverColor: '#ebebeb',
  rowAlternateColor: '#f5f5f5',
  rowHoverHighlight: '#e0f7fa',
};

const PremiumButton = styled(Button)(({ variant }) => ({
  borderRadius: '6px',
  padding: '6px 12px',
  textTransform: 'none',
  fontWeight: 'bold',
  height: '30px',
  minWidth: '80px',
  fontSize: '12px',
  transition: 'background-color 0.3s ease, transform 0.3s ease',
  cursor: 'pointer',
  border: 'none',
  boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)',
  background: variant === 'contained' ? themeColor.primary : 'transparent',
  color: variant === 'contained' ? '#fff' : themeColor.primary,
  border: variant === 'outlined' ? `1px solid ${themeColor.primary}` : 'none',
  '&:hover': {
    backgroundColor: variant === 'contained' ? themeColor.primaryDark : 'rgba(51, 51, 51, 0.05)',
    transform: 'scale(1.03)',
  },
  '&:active': {
    transform: 'scale(0.97)',
  },
}));
const StyledTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    fontSize: '14px',
  },
});
const StyledTableCell = styled(TableCell)({
  fontSize: '12px',
  borderBottom: `1px solid ${themeColor.borderColor}`,
  padding: '6px 8px',
  textAlign: 'center',
  color: themeColor.color,
});

const StyledTableRow = styled(TableRow)({
  cursor: 'pointer',
  height: '40px',
  transition: 'background-color 0.3s ease',
  '&:nth-of-type(odd)': {
    backgroundColor: '#ffffff',
  },
  '&:hover': {
    backgroundColor: themeColor.rowHoverHighlight,
    boxShadow: '0px 2px 4px rgba(0,0,0,0.05)',
  },
});

const StyledTableHead = styled(TableHead)({
  backgroundColor: themeColor.primary,
  color: themeColor.headerTextColor,
  position: 'sticky',
  top: 0,
  zIndex: 2,
  '& th': {
    fontSize: '13px',
    fontWeight: 'bold',
    padding: '10px 12px',
    textAlign: 'center',
    borderRight: `1px solid ${themeColor.borderColor}`,
    background: themeColor.primary,
    color: themeColor.headerTextColor,
    '&:last-child': {
      borderRight: 'none',
    },
  },
});

const StyledTextArea = styled(TextField)({
  '& .MuiInputBase-root': {
    fontSize: '14px',
  },
});

const DetailImage = styled('img')({
  width: '70%',
  height: 'auto',
  borderRadius: '8px',
  boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
  marginBottom: '16px',
});

const Vendormgt = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showDetailPopup, setShowDetailPopup] = useState(false);
  const [showAddVendorPopup, setShowAddVendorPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newVendor, setNewVendor] = useState({
    name: '',
    des: '',
    wlink: '',
    image_data: null,
    status: 'enabled',
    pillars: {
      Perimeter_and_internal_security: false,
      Cyber_Security_Governance_Compliance: false,
      Authentication_Identity_Management: false,
      Security_Management: false,
      Endpoint_Security: false,
      Networking: false,
      Data_Center_Infrastructure_and_Infrastructure_Monitoring: false,
      Server_Storage_Backup_Solutions: false,
    },
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [imageFile, setImageFile] = useState(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');

  const fetchVendors = async () => {
    setLoading(true); // Start loading
    const cnt = localStorage.getItem('cnt');
    try {
      const response = await axios.get(`${apilinkmain}/vendors`, {
        withCredentials: true,
        headers: { cnt: cnt }
      });
      setData(response.data);
      setFilteredData(response.data);
    } catch (error) {
      console.error('Error fetching vendors:', error);
      Swal.fire('Error', 'Failed to fetch vendor data.', 'error');
    } finally {
      setLoading(false); // End loading
    }
  };
  useEffect(() => {
    

    fetchVendors();
  }, []);

  const handleRowClick = (user) => {
    const mappedPillars = mapPillars(user);
    
    setSelectedUser({
      ...user,
      pillars: mappedPillars,
    });
    setShowDetailPopup(true);
  };
  
  const mapPillars = (user) => {
    return {
      Perimeter_and_internal_security: user.Perimeter_and_internal_security === "true" || user.Perimeter_and_internal_security === true,
      Cyber_Security_Governance_Compliance: user.Cyber_Security_Governance_Compliance === "true" || user.Cyber_Security_Governance_Compliance === true,
      Authentication_Identity_Management: user.Authentication_Identity_Management === "true" || user.Authentication_Identity_Management === true,
      Security_Management: user.Security_Management === "true" || user.Security_Management === true,
      Endpoint_Security: user.Endpoint_Security === "true" || user.Endpoint_Security === true,
      Networking: user.Networking === "true" || user.Networking === true,
      Data_Center_Infrastructure_and_Infrastructure_Monitoring: user.Data_Center_Infrastructure_and_Infrastructure_Monitoring === "true" || user.Data_Center_Infrastructure_and_Infrastructure_Monitoring === true,
      Server_Storage_Backup_Solutions: user.Server_Storage_Backup_Solutions === "true" || user.Server_Storage_Backup_Solutions === true,
    };
  };
  

  const handleCloseDetailPopup = () => {
    setShowDetailPopup(false);
    setSelectedUser(null);
  };

  const handleAddVendorClick = () => {
    setShowAddVendorPopup(true);
  };

  const handleCloseAddVendorPopup = () => {
    setShowAddVendorPopup(false);
  };

  const handlePillarChange = (event) => {
    const { name, checked } = event.target;
    setNewVendor((prevVendor) => ({
      ...prevVendor,
      pillars: {
        ...prevVendor.pillars,
        [name]: checked,
      },
    }));
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewVendor((prevVendor) => ({
          ...prevVendor,
          image_data: reader.result.split(',')[1], // Store Base64 data without prefix
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const resetNewVendorState = () => {
    setNewVendor({
      name: '',
      des: '',
      wlink: '',
      image_data: null,
      status: 'enabled',
      pillars: {
        Perimeter_and_internal_security: false,
        Cyber_Security_Governance_Compliance: false,
        Authentication_Identity_Management: false,
        Security_Management: false,
        Endpoint_Security: false,
        Networking: false,
        Data_Center_Infrastructure_and_Infrastructure_Monitoring: false,
        Server_Storage_Backup_Solutions: false,
      },
    });
    setImageFile(null);
  };

  const handleSubmitNewVendor = async () => {
    const cnt = localStorage.getItem('cnt');
    const hasAtLeastOnePillar = Object.values(newVendor.pillars).some(Boolean);

    if (!newVendor.name || !newVendor.wlink || !hasAtLeastOnePillar) {
      setShowAddVendorPopup(false);
      Swal.fire('Validation Error', 'Please fill out all required fields and select at least one Pillar.', 'error');
      return;
    }

    const vendorData = {
      ...newVendor,
      pillars: newVendor.pillars, // Send the pillars object as is (boolean values)
    };

    const formData = new FormData();
    formData.append('name', vendorData.name);
    formData.append('des', vendorData.des);
    formData.append('wlink', vendorData.wlink);
    formData.append('status', vendorData.status);
    formData.append('Perimeter_and_internal_security', vendorData.pillars.Perimeter_and_internal_security);
    formData.append('Cyber_Security_Governance_Compliance', vendorData.pillars.Cyber_Security_Governance_Compliance);
    formData.append('Authentication_Identity_Management', vendorData.pillars.Authentication_Identity_Management);
    formData.append('Security_Management', vendorData.pillars.Security_Management);
    formData.append('Endpoint_Security', vendorData.pillars.Endpoint_Security);
    formData.append('Networking', vendorData.pillars.Networking);
    formData.append('Data_Center_Infrastructure_and_Infrastructure_Monitoring', vendorData.pillars.Data_Center_Infrastructure_and_Infrastructure_Monitoring);
    formData.append('Server_Storage_Backup_Solutions', vendorData.pillars.Server_Storage_Backup_Solutions);

    if (imageFile) {
      formData.append('image', imageFile); // Attach the file directly
    }

    try {
      const response = await axios.post(`${apilinkmain}/vendor`, formData, {
        withCredentials: true,
        headers: { cnt: cnt }
      });

      if (response.status === 200) {
        const newVendorData = { ...vendorData, id: response.data.vendorId };
        setData([...data, newVendorData]);
        setFilteredData([...data, newVendorData]);
        setShowAddVendorPopup(false);
        fetchVendors();
        Swal.fire('Success', 'Vendor updated successfully.', 'success').then(() => {
          resetNewVendorState();
          fetchVendors();
        });
        
      }
    } catch (error) {
      console.error('Error adding vendor:', error);
      Swal.fire('Error', 'Failed to add vendor.', 'error');
    }
  };

  const handleUpdateVendor = async () => {
    const vendorData = {
      ...selectedUser,
      pillars: selectedUser.pillars, // Send the pillars object as is (boolean values)
    };

    const formData = new FormData();
    formData.append('name', vendorData.name);
    formData.append('des', vendorData.des);
    formData.append('wlink', vendorData.wlink);
    formData.append('status', vendorData.status);
    formData.append('Perimeter_and_internal_security', vendorData.pillars.Perimeter_and_internal_security);
    formData.append('Cyber_Security_Governance_Compliance', vendorData.pillars.Cyber_Security_Governance_Compliance);
    formData.append('Authentication_Identity_Management', vendorData.pillars.Authentication_Identity_Management);
    formData.append('Security_Management', vendorData.pillars.Security_Management);
    formData.append('Endpoint_Security', vendorData.pillars.Endpoint_Security);
    formData.append('Networking', vendorData.pillars.Networking);
    formData.append('Data_Center_Infrastructure_and_Infrastructure_Monitoring', vendorData.pillars.Data_Center_Infrastructure_and_Infrastructure_Monitoring);
    formData.append('Server_Storage_Backup_Solutions', vendorData.pillars.Server_Storage_Backup_Solutions);

    if (imageFile) {
      formData.append('image', imageFile);
    }

    try {
      const response = await axios.put(`${apilinkmain}/vendor/${vendorData.id}`, formData, {
        withCredentials: true,
      });

      if (response.status === 200) {
        const updatedData = data.map((vendor) =>
          vendor.id === vendorData.id ? { ...vendorData } : vendor
        );
        setData(updatedData);
        setFilteredData(updatedData);
        setShowDetailPopup(false);
        fetchVendors();
        Swal.fire('Success', 'Vendor updated successfully.', 'success').then(() => {
          
          fetchVendors();
        });
        
      }
    } catch (error) {
      console.error('Error updating vendor:', error);
      Swal.fire('Error', 'Failed to update vendor.', 'error');
    }
  };

  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    setFilteredData(data.filter((vendor) => vendor.name.toLowerCase().includes(query)));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedData = () => {
    return filteredData.sort((a, b) => {
      if (orderBy === 'id' || orderBy === 'status') {
        return (order === 'asc' ? a[orderBy] > b[orderBy] : a[orderBy] < b[orderBy]) ? 1 : -1;
      } else {
        return (order === 'asc' ? a[orderBy].localeCompare(b[orderBy]) : b[orderBy].localeCompare(a[orderBy]));
      }
    });
  };

  return (
    <Box sx={{ padding: 2, overflowY: 'hidden', height: '83vh' }}>
      <Typography variant="h5">Vendor Management</Typography>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', width: '40%', marginLeft: '10px', marginTop: '10px' }}>
          <TextField
            label="Search by Company Name"
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={handleSearchChange}
            sx={{ flexGrow: 1 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', width: '25%', justifyContent: 'flex-end' }}>
          <PremiumButton
            variant="contained"
            onClick={handleAddVendorClick}
            sx={{ marginRight: 1 }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={20} /> : '+ Add Vendor'}
          </PremiumButton>
        </Box>
      </Box>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer
        component={Paper}
        sx={{
            overflow: 'auto',
            height: '400px',
            marginBottom: '20px',
            borderRadius: '8px',
            boxShadow: '0 3px 6px rgba(0,0,0,0.1)',
            '&::-webkit-scrollbar': {
                width: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: themeColor.primaryDark,
                borderRadius: '4px',
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: themeColor.primaryLight,
            },
        }}
        >
          <Table>
            <StyledTableHead>
              <TableRow>
                <StyledTableCell>
                    Vendor ID
                </StyledTableCell>
                <StyledTableCell>
                  
                    Vendor Name
                  
                </StyledTableCell>
                <StyledTableCell>
                    Website
                
                </StyledTableCell>
                <StyledTableCell>
                    Status
                 
                </StyledTableCell>
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {filteredData.map((vendor) => (
                <StyledTableRow key={vendor.id} onClick={() => handleRowClick(vendor)}>
                  <StyledTableCell>{vendor.id}</StyledTableCell>
                  <StyledTableCell>{vendor.name}</StyledTableCell>
                  <StyledTableCell>{vendor.wlink}</StyledTableCell>
                  <StyledTableCell>{vendor.status}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}



      <Dialog
        open={showDetailPopup}
        onClose={handleCloseDetailPopup}
        PaperProps={{
          style: {
            width: '100vh',
            borderRadius: '8px',
            boxShadow: '0px 3px 6px rgba(0,0,0,0.2)',
          },
        }}
        TransitionComponent={Zoom}
        TransitionProps={{ timeout: 400 }}
      >
        <DialogContent>
          {selectedUser && (
            <>
              {selectedUser.image_data && (
                <DetailImage
                  sx={{ width: '50%', marginLeft: '25%' }}
                  src={`data:image/jpeg;base64,${selectedUser.image_data}`}
                  alt={selectedUser.name}
                />
              )}
              <StyledTextField
                label="Vendor Name"
                fullWidth
                margin="normal"
                value={selectedUser.name}
                onChange={(e) =>
                  setSelectedUser({
                    ...selectedUser,
                    name: e.target.value,
                  })
                }
                disabled={loading}
              />
              <StyledTextArea
                label="Description"
                fullWidth
                margin="normal"
                multiline
                rows={4}
                value={selectedUser.des}
                onChange={(e) =>
                  setSelectedUser({
                    ...selectedUser,
                    des: e.target.value,
                  })
                }
                disabled={loading}
              />
              <Table>
                <TableBody>
                  <TableRow>
                    <StyledTableCell>Website:</StyledTableCell>
                    <StyledTableCell>
                      <TextField
                        fullWidth
                        value={selectedUser.wlink}
                        onChange={(e) =>
                          setSelectedUser({
                            ...selectedUser,
                            wlink: e.target.value,
                          })
                        }
                        disabled={loading}
                      />
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell>Status:</StyledTableCell>
                    <StyledTableCell>
                      <Select
                        fullWidth
                        value={selectedUser.status}
                        onChange={(e) =>
                          setSelectedUser({
                            ...selectedUser,
                            status: e.target.value,
                          })
                        }
                        disabled={loading}
                      >
                        <MenuItem value="enabled">Enabled</MenuItem>
                        <MenuItem value="disabled">Disabled</MenuItem>
                      </Select>
                    </StyledTableCell>
                  </TableRow>
                  {Object.keys(selectedUser.pillars).map((pillar, index) => (
                    <TableRow key={index}>
                      <StyledTableCell>
                        {pillar.replace(/_/g, ' ')}:
                      </StyledTableCell>
                      <StyledTableCell>
                        <Checkbox
                          checked={selectedUser.pillars[pillar]}
                          onChange={(e) =>
                            setSelectedUser({
                              ...selectedUser,
                              pillars: {
                                ...selectedUser.pillars,
                                [pillar]: e.target.checked,
                              },
                            })
                          }
                          name={pillar}
                          disabled={loading}
                        />
                      </StyledTableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <StyledTableCell>Upload Image:</StyledTableCell>
                    <StyledTableCell>
                      <input
                        type="file"
                        onChange={handleImageChange}
                        accept="image/*"
                        disabled={loading}
                      />
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <PremiumButton
            variant="outlined"
            color="error"
            onClick={handleCloseDetailPopup}
            disabled={loading}
          >
            Cancel
          </PremiumButton>
          <PremiumButton variant="contained" onClick={handleUpdateVendor} disabled={loading}>
            {loading ? <CircularProgress size={20} /> : 'Update'}
          </PremiumButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showAddVendorPopup}
        onClose={handleCloseAddVendorPopup}
        PaperProps={{
          style: {
            width: '100vh',
            borderRadius: '8px',
            boxShadow: '0px 3px 6px rgba(0,0,0,0.2)',
          },
        }}
        TransitionComponent={Zoom}
        TransitionProps={{ timeout: 400 }}
      >
        <DialogContent>
          <Typography variant="h6" gutterBottom>
            Add New Vendor Form
          </Typography>
          <StyledTextField
            label="Vendor Name"
            fullWidth
            margin="normal"
            value={newVendor.name}
            onChange={(e) =>
              setNewVendor({ ...newVendor, name: e.target.value })
            }
            disabled={loading}
          />
          <StyledTextArea
            label="Description (500 words)"
            fullWidth
            margin="normal"
            multiline
            rows={4}
            value={newVendor.des}
            onChange={(e) =>
              setNewVendor({ ...newVendor, des: e.target.value })
            }
            disabled={loading}
          />
          <StyledTextField
            label="Website"
            fullWidth
            margin="normal"
            value={newVendor.wlink}
            onChange={(e) =>
              setNewVendor({ ...newVendor, wlink: e.target.value })
            }
            disabled={loading}
          />

          <Box sx={{ marginBottom: '16px' }}>
            <Typography variant="body2" gutterBottom>
              Upload Image:
            </Typography>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{ marginBottom: '16px' }}
              disabled={loading}
            />
            {newVendor.image_data && (
              <DetailImage
                sx={{ width: '50%', marginLeft: '25%' }}
                src={`data:image/jpeg;base64,${newVendor.image_data}`}
                alt="Vendor Preview"
              />
            )}
          </Box>

          <FormGroup>
            <Select
              label="Status"
              value={newVendor.status}
              fullWidth
              onChange={(e) =>
                setNewVendor({ ...newVendor, status: e.target.value })
              }
              disabled={loading}
            >
              <MenuItem value="enabled">Enabled</MenuItem>
              <MenuItem value="disabled">Disabled</MenuItem>
            </Select>
          </FormGroup>
          <Typography variant="body2" gutterBottom>
            Pillars:
          </Typography>
          <FormGroup>
            {Object.keys(newVendor.pillars).map((pillar, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={newVendor.pillars[pillar]}
                    onChange={handlePillarChange}
                    name={pillar}
                    disabled={loading}
                  />
                }
                label={pillar.replace(/_/g, ' ')}
              />
            ))}
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <PremiumButton
            variant="outlined"
            color="error"
            onClick={handleCloseAddVendorPopup}
            disabled={loading}
          >
            Cancel
          </PremiumButton>
          <PremiumButton variant="contained" onClick={handleSubmitNewVendor} disabled={loading}>
            {loading ? <CircularProgress size={20} /> : 'Submit'}
          </PremiumButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Vendormgt;
