import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import { apilinkmain } from './api.js';

const ProtectedRoute = ({ children}) => {
  const [isVerified, setIsVerified] = useState(null);

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const response = await axios.get(`${apilinkmain}/verifytoken`, {
          withCredentials: true, // Include cookies in the request
        });
        console.log("test:"+response)
        if (response.status === 200) {
          setIsVerified(true);
        } else {
          setIsVerified(false);
        }
      } catch (error) {
        console.error('Error verifying token:', error);
        setIsVerified(false);
      }
    };

    verifyToken();
  }, [apilinkmain]); // Re-run the effect if apilinkmain changes

  if (isVerified === null) {
    // Optionally render a loading state while the token is being verified
    return <div>Loading...</div>;
  }

  if (!isVerified) {
    return <Navigate to="/" />;
  }

  return children;
};

export default ProtectedRoute;
