import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Sidebar from './compo/Sidebar';
import DashboardPage from './compo/page/DashboardPage';
import Eventreg from './compo/page/Eventreg.js';
import bcrypt from 'bcryptjs';
import ProtectedRoute from './compo/ProtectedRoute.js';
import {
  Box,
  IconButton,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Zoom,
  Button,
  TextField,
} from '@mui/material';
import { Edit, Person, Logout } from '@mui/icons-material';
import ChatPopup from '../src/compo/page/ChatPopup'; // Import the ChatPopup component
import LoginPage from './compo/page/LoginPage.js';
import VendorMgt from './compo/page/Vendormgt.js';
import Events from './compo/page/Events.js';
import Contact from './compo/page/Contact.js';
import Blog from './compo/page/Blog.js';
import Swal from 'sweetalert2';
import News from './compo/page/News.js';
import NotFoundPage from './compo/page/NotFoundPage'; // Import the NotFoundPage component
import axios from 'axios';
import { apilinkmain } from './compo/api.js';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';

// Theme color palette
const themeColors = {
  primary: '#333333',
  primaryDark: '#222222',
  secondary: '#25D366',
  textPrimary: '#ffffff',
  textSecondary: '#666666',
  background: '#f4f4f4',
  border: '#e0e0e0',
  cardBg: '#fafafa',
};

// Styles for header, footer, and dialogs
const styles = {
  header: {
    padding: '10px 20px',
    backgroundColor: '#03163c',
    color: themeColors.textPrimary,
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: '500',
    fontFamily: "'Roboto', sans-serif",
    borderBottom: `1px solid ${themeColors.border}`,
    margin: 0,
    position: 'fixed',
    top: 0,
    right: 0,
    width: '100%',
    zIndex: 1200,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleBox: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '20px',
  },
  footer: {
    padding: '10px 20px',
    backgroundColor: '#03163c',
    textAlign: 'center',
    borderTop: `1px solid ${themeColors.border}`,
    fontSize: '12px',
    color: themeColors.textSecondary,
    fontFamily: "'Roboto', sans-serif",
    margin: 0,
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
  },
  dialogContent: {
    padding: '20px',
    backgroundColor: 'rgba(255, 255, 255, 0.3)', // White with 0.3 opacity
    borderRadius: '12px', // Rounded corners
  },
  profileImage: {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    marginBottom: '20px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  profileField: {
    marginBottom: '10px',
  },
  fieldLabel: {
    fontWeight: 'bold',
    color: themeColors.primary,
    fontSize: '14px',
  },
  fieldValue: {
    color: themeColors.textSecondary,
    fontSize: '14px',
  },
  iconButton: {
    color: themeColors.textPrimary,
    fontSize: '2rem',
    background: 'rgba(255, 255, 255, 0.3)', // White with 0.3 opacity
    marginBottom: '5px',
    transition: 'transform 0.3s',
    '&:hover': {
      transform: 'scale(1.1)',
      background: 'rgba(255, 255, 255, 0.5)', // Slightly more opaque on hover
    },
  },
  profileDialogTitle: {
    fontWeight: 'bold',
    color: themeColors.primary,
    fontSize: '18px',
    textAlign: 'left', // Align to top-left corner
    marginBottom: '10px',
    paddingLeft: '20px',
  },
  dialogPaper: {
    borderRadius: '12px',
    boxShadow: '0px 6px 12px rgba(0,0,0,0.15)',
    backgroundColor: themeColors.cardBg,
    width: '600px', // Reduced width for a compact profile view
  },
  textFieldRoot: {
    '& .MuiInput-underline:before': {
      borderBottomColor: themeColors.primary, // Custom underline color
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: themeColors.secondary, // Custom underline color on focus
    },
  },
  passwordDialogTitle: {
    textAlign: 'center',
    fontWeight: 'bold',
    color: themeColors.primary,
  },
  chatIconButton: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    backgroundColor: themeColors.secondary,
    color: '#fff',
    '&:hover': {
      backgroundColor: '#20c65a', // Slightly lighter green
    },
  },
};

const AppContent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openProfileDialog, setOpenProfileDialog] = useState(false);
  const [openEditProfileDialog, setOpenEditProfileDialog] = useState(false);
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState(''); // New state for retype password
  const [showPassword, setShowPassword] = useState(false);
  const [openChatPopup, setOpenChatPopup] = useState(false);
  const [editedUser, setEditedUser] = useState(null);
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false); // State for logout confirmation dialog
  const [passwordError, setPasswordError] = useState(''); // State for password error message

  const userId = localStorage.getItem('usn'); // Assuming `usn` stores the `userId`

  // Handle mobile responsiveness
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (openProfileDialog && userId) {
      // Fetch user details when the profile dialog is opened
      const fetchUserDetails = async () => {
        try {
          const response = await axios.get(`${apilinkmain}/user/${userId}`, {
            withCredentials: true, // Ensure cookies are included in the request
          });
          setEditedUser(response.data);
        } catch (error) {
          console.error('Error fetching user details:', error);
        }
      };

      fetchUserDetails();
    }
  }, [openProfileDialog, userId]);

  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenProfileDialog(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditProfile = () => {
    setOpenEditProfileDialog(true);
    setOpenProfileDialog(false);
  };

  const handleChangePassword = () => {
    setOpenPasswordDialog(true);
    setOpenProfileDialog(false);
  };

  const handlePasswordChange = async () => {
    const id = localStorage.getItem('usn');
    if (newPassword !== retypePassword) {
      setPasswordError('Passwords do not match');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('password',newPassword);
      const hashedPassword = await bcrypt.hash(newPassword, 10);
      const response = await axios.put(`${apilinkmain}/user/change-password`, formData, {
        withCredentials: true,
        headers: { cnt: id,pass:hashedPassword }
      });

      if (response.status === 200) {
        Swal.fire('Success', 'Password Update successfully.', 'success');
        setOpenPasswordDialog(false);
        setNewPassword('');
        setRetypePassword('');
        setPasswordError('');
      } else {
        console.error('Error changing password:', response.data.message);
      }
    } catch (error) {
      console.error('An error occurred while changing password:', error);
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleProfileFieldChange = (field, value) => {
    setEditedUser({ ...editedUser, [field]: value });
  };

  const handleProfileUpdate = async () => {
    try {
      // Update the user profile on the server
      const response = await axios.put(`${apilinkmain}/user/${userId}`, editedUser, {
        withCredentials: true,
      });

      if (response.status === 200) {
        console.log('Profile updated successfully:', response.data);
        setOpenProfileDialog(false);
      } else {
        console.error('Error updating profile:', response.data.message);
      }
    } catch (error) {
      console.error('An error occurred while updating profile:', error);
    }
  };

  const onLogin = () => {
    console.log('User logged in');
  };

  const handleLogout = () => {
    setLogoutDialogOpen(true); // Open the logout confirmation dialog
  };

  const confirmLogout = async () => {
    try {
      // Call the logout API
      const response = await axios.post(`${apilinkmain}/logout`, {}, {
        withCredentials: true, // Ensure cookies are included in the request
      });

      if (response.status === 200) {
        // Successfully logged out
        setLogoutDialogOpen(false); // Close the dialog after logout
        navigate('/'); // Redirect to the homepage
      } else {
        console.error('Logout failed:', response.data.message);
      }
    } catch (error) {
      console.error('An error occurred during logout:', error);
    }
  };

  const cancelLogout = () => {
    setLogoutDialogOpen(false); // Close the dialog without logging out
  };

  const validRoutes = [
    '/vendormgt',
    '/eventreg',
    '/events',
    '/contact',
    '/blog',
    '/news',
    '/dashboard',
  ];

  const shouldShowSidebarAndHeader = validRoutes.includes(location.pathname);

  return (
    <>
      {shouldShowSidebarAndHeader && (
        <header style={styles.header}>
          <Box style={styles.titleBox}>
            <Typography variant="h6" color="inherit">
              Web Site Management Portal
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" marginRight="20px">
            <IconButton
              onClick={handleProfileClick}
              style={styles.iconButton}
              aria-label="Profile"
            >
              <Person />
            </IconButton>
            <IconButton
              sx={{ marginLeft: '10px' }}
              onClick={handleLogout}
              style={styles.iconButton}
              aria-label="Logout"
            >
              <Logout />
            </IconButton>
          </Box>
        </header>
      )}
      <div style={{ display: 'flex', flex: 1, marginTop: shouldShowSidebarAndHeader ? '60px' : '0' }}>
        {shouldShowSidebarAndHeader && <Sidebar />}
        <main style={{ flex: 1, marginLeft: isMobile || !shouldShowSidebarAndHeader ? 0 : '200px', padding: isMobile ? '10px' : '20px' }}>
          <Routes>
            <Route path="/" element={<LoginPage />} /> {/* Default route */}
            <Route path="/eventreg" element={<ProtectedRoute><Eventreg /></ProtectedRoute>} />
            <Route path="/vendormgt" element={<ProtectedRoute><VendorMgt /></ProtectedRoute>} />
            <Route path="/events" element={<ProtectedRoute><Events /></ProtectedRoute>} />
            <Route path="/contact" element={<ProtectedRoute><Contact /></ProtectedRoute>} />
            <Route path="/blog" element={<ProtectedRoute><Blog /></ProtectedRoute>} />
            <Route path="/news" element={<ProtectedRoute><News /></ProtectedRoute>} />
            <Route path="/dashboard" element={<ProtectedRoute><DashboardPage /></ProtectedRoute>} />
            {/* Wildcard Route for 404 */}
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </main>
      </div>
      {shouldShowSidebarAndHeader && (
        <footer style={styles.footer}>
          <Typography variant="body2" style={{ margin: '0', fontWeight: '300' }}>
            Designed and Developed by <strong>CODE-WORKS Software Engineer Team</strong> © 2024 Connex Information Technologies (PVT) LTD
          </Typography>
          <ChatPopup open={openChatPopup} onClose={() => setOpenChatPopup(false)} />
        </footer>
      )}

      {/* Profile Dialog */}
      <Dialog
        open={openProfileDialog}
        onClose={() => setOpenProfileDialog(false)}
        maxWidth="sm" // Reduced width for a compact profile view
        fullWidth
        PaperProps={{
          style: styles.dialogPaper,
        }}
        TransitionComponent={Zoom}
        TransitionProps={{ timeout: 400 }}
      >
        <DialogContent style={styles.dialogContent}>
          <Grid container spacing={2} alignItems="center">
            {editedUser && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h6" style={styles.profileDialogTitle}>
                    User Profile
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} style={{ display: 'flex', justifyContent: 'center' }}>
                  {editedUser && editedUser.image_data ? (
                     <img
                     src={editedUser.imageData}  // Correctly formatted base64 image data
                     alt="User"
                     style={styles.profileImage}
                   />
                 ) : (
                   <img
                     src={editedUser.imageData}  // Fallback to default logo
                     alt="Default"
                     style={styles.profileImage}
                   />
                  )}
                </Grid>

                <Grid item xs={12} md={8}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} style={styles.profileField}>
                      <Typography variant="body1" style={styles.fieldLabel}>
                        Full Name:
                      </Typography>
                      <Typography variant="body1" style={styles.fieldValue}>
                        {editedUser.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} style={styles.profileField}>
                      <Typography variant="body1" style={styles.fieldLabel}>
                        Employee ID:
                      </Typography>
                      <Typography variant="body1" style={styles.fieldValue}>
                        {editedUser.empno}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} style={styles.profileField}>
                      <Typography variant="body1" style={styles.fieldLabel}>
                        Email:
                      </Typography>
                      <Typography variant="body1" style={styles.fieldValue}>
                        {editedUser.email}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} style={styles.profileField}>
                      <Typography variant="body1" style={styles.fieldLabel}>
                        Phone No:
                      </Typography>
                      <Typography variant="body1" style={styles.fieldValue}>
                        {editedUser.phoneno}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          {/* <Button
            onClick={handleEditProfile}
            color="primary"
            startIcon={<Edit />}
            sx={{
              textTransform: 'none',
              fontWeight: 'bold',
              fontSize: '14px',
              transition: 'background-color 0.3s ease, transform 0.3s ease',
              '&:hover': {
                backgroundColor: themeColors.primaryDark,
                color: themeColors.textPrimary,
                transform: 'scale(1.05)',
              },
            }}
          >
            Edit Profile
          </Button> */}
          <Button
            onClick={handleChangePassword}
            color="primary"
            startIcon={<Edit />}
            sx={{
              textTransform: 'none',
              fontWeight: 'bold',
              fontSize: '14px',
              transition: 'background-color 0.3s ease, transform 0.3s ease',
              '&:hover': {
                backgroundColor: themeColors.primaryDark,
                color: themeColors.textPrimary,
                transform: 'scale(1.05)',
              },
            }}
          >
            Change Password
          </Button>
          <Button
            onClick={() => setOpenProfileDialog(false)}
            color="secondary"
            sx={{
              textTransform: 'none',
              fontWeight: 'bold',
              fontSize: '14px',
              transition: 'background-color 0.3s ease, transform 0.3s ease',
              '&:hover': {
                backgroundColor: themeColors.primaryDark,
                color: themeColors.textPrimary,
                transform: 'scale(1.05)',
              },
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Change Password Dialog */}
      <Dialog
        open={openPasswordDialog}
        onClose={() => setOpenPasswordDialog(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: styles.dialogPaper,
        }}
        TransitionComponent={Zoom}
        TransitionProps={{ timeout: 400 }}
      >
        <DialogContent style={styles.dialogContent}>
          <Typography variant="h6" style={styles.passwordDialogTitle}>
            Change Password
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="New Password"
                type="password"
                fullWidth
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                error={!!passwordError}
                helperText={passwordError}
                InputProps={{
                  style: styles.textFieldRoot,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Retype New Password"
                type="password"
                fullWidth
                value={retypePassword}
                onChange={(e) => setRetypePassword(e.target.value)}
                error={!!passwordError}
                helperText={passwordError}
                InputProps={{
                  style: styles.textFieldRoot,
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handlePasswordChange}
            color="primary"
            sx={{
              textTransform: 'none',
              fontWeight: 'bold',
              fontSize: '14px',
              transition: 'background-color 0.3s ease, transform 0.3s ease',
              '&:hover': {
                backgroundColor: themeColors.primaryDark,
                color: themeColors.textPrimary,
                transform: 'scale(1.05)',
              },
            }}
          >
            Change Password
          </Button>
          <Button
            onClick={() => setOpenPasswordDialog(false)}
            color="secondary"
            sx={{
              textTransform: 'none',
              fontWeight: 'bold',
              fontSize: '14px',
              transition: 'background-color 0.3s ease, transform 0.3s ease',
              '&:hover': {
                backgroundColor: themeColors.primaryDark,
                color: themeColors.textPrimary,
                transform: 'scale(1.05)',
              },
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Logout Confirmation Dialog */}
      <Dialog
        open={logoutDialogOpen}
        onClose={cancelLogout}
        maxWidth="xs"
        fullWidth
        PaperProps={{
          style: styles.dialogPaper,
        }}
        TransitionComponent={Zoom}
        TransitionProps={{ timeout: 400 }}
      >
        <DialogContent style={styles.dialogContent}>
          <Typography variant="body1" style={styles.fieldLabel}>
            Are you sure you want to log out?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={confirmLogout}
            color="primary"
            sx={{
              textTransform: 'none',
              fontWeight: 'bold',
              fontSize: '14px',
              transition: 'background-color 0.3s ease, transform 0.3s ease',
              '&:hover': {
                backgroundColor: themeColors.primaryDark,
                color: themeColors.textPrimary,
                transform: 'scale(1.05)',
              },
            }}
          >
            Yes
          </Button>
          <Button
            onClick={cancelLogout}
            color="secondary"
            sx={{
              textTransform: 'none',
              fontWeight: 'bold',
              fontSize: '14px',
              transition: 'background-color 0.3s ease, transform 0.3s ease',
              '&:hover': {
                backgroundColor: themeColors.primaryDark,
                color: themeColors.textPrimary,
                transform: 'scale(1.05)',
              },
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

function App() {
  return (
    <Router>
      <CssBaseline /> {/* Normalize CSS across browsers */}
      <AppContent />
    </Router>
  );
}

export default App;
