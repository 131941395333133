// src/compo/page/NotFoundPage.js
import React from 'react';
import { Typography, Box } from '@mui/material';

const NotFoundPage = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      flexDirection="column"
      textAlign="center"
    >
      <Typography variant="h3" color="primary">
        404
      </Typography>
      <Typography variant="h5" color="textSecondary">
        Page Not Found
      </Typography>
      <Typography variant="body1" color="textSecondary">
        Sorry, the page you are looking for does not exist.
      </Typography>
    </Box>
  );
};

export default NotFoundPage;
