import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  InputAdornment,
} from '@mui/material';
import { styled } from '@mui/system';
import Swal from 'sweetalert2';
import { Search } from '@mui/icons-material';
import { apilinkmain } from '../api';  // Adjust the import according to your project structure

// Theme color
const themeColor = {
  primary: '#444',
  primaryDark: '#666',
  success: '#4caf50',
  error: '#f44336',
  headerBg: '#444',
  headerTextColor: '#ffffff',
  borderColor: '#777',
  color: '#000000',
  rowHoverColor: '#ebebeb',
  rowAlternateColor: '#f5f5f5',
  rowHoverHighlight: '#e0f7fa',
};

// Styled components
const PremiumButton = styled(Button)(({ variant }) => ({
  borderRadius: '6px',
  padding: '6px 12px',
  textTransform: 'none',
  fontWeight: 'bold',
  height: '30px',
  minWidth: '80px',
  fontSize: '12px',
  transition: 'background-color 0.3s ease, transform 0.3s ease',
  cursor: 'pointer',
  border: 'none',
  boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)',
  background: variant === 'contained' ? themeColor.primary : 'transparent',
  color: variant === 'contained' ? '#fff' : themeColor.primary,
  border: variant === 'outlined' ? `1px solid ${themeColor.primary}` : 'none',
  '&:hover': {
    backgroundColor: variant === 'contained' ? themeColor.primaryDark : 'rgba(51, 51, 51, 0.05)',
    transform: 'scale(1.03)',
  },
  '&:active': {
    transform: 'scale(0.97)',
  },
}));

const StyledTableCell = styled(TableCell)({
  fontSize: '12px',
  borderBottom: `1px solid ${themeColor.borderColor}`,
  padding: '6px 8px',
  textAlign: 'center',
  color: themeColor.color,
});

const StyledTableRow = styled(TableRow)({
  cursor: 'pointer',
  height: '40px',
  transition: 'background-color 0.3s ease',
  '&:nth-of-type(odd)': {
    backgroundColor: '#ffffff',
  },
  '&:hover': {
    backgroundColor: themeColor.rowHoverHighlight,
    boxShadow: '0px 2px 4px rgba(0,0,0,0.05)',
  },
});

const StyledTableHead = styled(TableHead)({
  backgroundColor: themeColor.primary,
  color: themeColor.headerTextColor,
  position: 'sticky',
  top: 0,
  zIndex: 2,
  '& th': {
    fontSize: '13px',
    fontWeight: 'bold',
    padding: '10px 12px',
    textAlign: 'center',
    borderRight: `1px solid ${themeColor.borderColor}`,
    background: themeColor.primary,
    color: themeColor.headerTextColor,
    '&:last-child': {
      borderRight: 'none',
    },
  },
});

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    fontSize: '14px',
  },
});

const StyledTextArea = styled(TextField)({
  '& .MuiInputBase-root': {
    fontSize: '14px',
  },
});

const DetailImage = styled('img')({
  width: '70%',
  height: 'auto',
  borderRadius: '8px',
  boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
  marginBottom: '16px',
});

// Component
const Events = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showDetailPopup, setShowDetailPopup] = useState(false);
  const [showAddVendorPopup, setShowAddVendorPopup] = useState(false);
  const [newVendor, setNewVendor] = useState({
    title: '',
    description: '',
    year: '',
    month: '',
    day: '',
    hour: '',
    minute: '',
    period: 'AM',
    seats: '',
    mode: 'Physical',
    image_data: null,
    status: 'enabled',
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [imageFile, setImageFile] = useState(null);

  // Fetch events data from the backend
  const fetchEvents = async () => {
    try {
      const cnt = localStorage.getItem('cnt');
      const response = await axios.get(`${apilinkmain}/events`, {
        withCredentials: true,
        headers: { cnt: cnt },
      });
      const events = response.data;
      setData(events);
      setFilteredData(events);
    } catch (error) {
      console.error('Error fetching events:', error);
      Swal.fire('Error', 'Failed to fetch events data.', 'error');
    }
  };

  useEffect(() => {
    fetchEvents(); // Fetch events when the component mounts
  }, []);

  // Handlers
  const handleRowClick = (vendor) => {
    // Split the date and time strings into components
    const [year, month, day] = vendor.date.split('-');
    const [hourMinute, period] = vendor.time.split(' ');
    const [hour, minute] = hourMinute.split(':');

    setSelectedUser({
      ...vendor,
      year,
      month,
      day,
      hour,
      minute,
      period,
    });
    setShowDetailPopup(true);
  };

  const handleCloseDetailPopup = () => {
    setShowDetailPopup(false);
    setSelectedUser(null);
  };

  const handleAddVendorClick = () => {
    setShowAddVendorPopup(true);
  };

  const handleCloseAddVendorPopup = () => {
    setShowAddVendorPopup(false);
  };

  const handleFieldChange = (field, value) => {
    setSelectedUser((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const formatDate = (year, month, day) => {
    return `${year}-${month}-${day}`;
  };

  const formatTime = (hour, minute, period) => {
    return `${hour}:${minute} ${period}`;
  };

  const validateFields = (year, month, day, hour, minute) => {
    const yearValid = year >= 2024 && year <= 2050;
    const monthValid = month >= 1 && month <= 12;
    const dayValid = day >= 1 && day <= 31;
    const hourValid = hour >= 1 && hour <= 12;
    const minuteValid = minute >= 0 && minute <= 59;

    return yearValid && monthValid && dayValid && hourValid && minuteValid;
  };

  const handleSubmitNewVendor = async () => {
    const { year, month, day, hour, minute, period } = newVendor;
    const formattedDate = formatDate(year, month, day);
    const formattedTime = formatTime(hour, minute, period);

    if (
      !newVendor.title ||
      !year || !month || !day ||
      !hour || !minute ||
      !newVendor.seats ||
      (isNaN(newVendor.seats) && newVendor.seats.toLowerCase() !== 'unlimited') ||
      !newVendor.description
    ) {
      Swal.fire('Validation Error', 'Please fill out all required fields.', 'error');
      return;
    }

    if (!validateFields(year, month, day, hour, minute)) {
      Swal.fire('Validation Error', 'Please enter valid date and time values.', 'error');
      return;
    }

    try { 
      const cnt =  localStorage.getItem('cnt')
      const formData = new FormData();
      formData.append('title', newVendor.title);
      formData.append('description', newVendor.description);
      formData.append('time', formattedTime);
      formData.append('date', formattedDate);
      formData.append('seats', newVendor.seats);
      formData.append('mode', newVendor.mode);
      formData.append('status', newVendor.status);
      formData.append	('cnt',cnt);

      if (imageFile) {
        formData.append('image', imageFile);
    }

      await axios.post(`${apilinkmain}/events`, formData, {
        withCredentials: true,
        headers: {cnt:cnt  },
      });

      Swal.fire('Success', 'New event has been added.', 'success');
      fetchEvents(); // Refresh the events list
      setShowAddVendorPopup(false);
      setNewVendor({
        title: '',
        description: '',
        year: '',
        month: '',
        day: '',
        hour: '',
        minute: '',
        period: 'AM',
        seats: '',
        mode: 'Physical',
        image_data: null,
        status: 'enabled',
      });
    } catch (error) {
      console.error('Error adding event:', error);
      Swal.fire('Error', 'Failed to add event.', 'error');
    }
  };

  const handleUpdateVendor = async () => {
    const { year, month, day, hour, minute, period } = selectedUser;
    const formattedDate = formatDate(year, month, day);
    const formattedTime = formatTime(hour, minute, period);

    const cnt = localStorage.getItem('cnt');
    if (
      !selectedUser.title ||
      !year || !month || !day ||
      !hour || !minute ||
      !selectedUser.seats ||
      (isNaN(selectedUser.seats) && selectedUser.seats.toLowerCase() !== 'unlimited') ||
      !selectedUser.description ||
      selectedUser.description.length < 1
    ) {
      Swal.fire('Validation Error', 'Please fill out all required fields.', 'error');
      return;
    }

    if (!validateFields(year, month, day, hour, minute)) {
      Swal.fire('Validation Error', 'Please enter valid date and time values.', 'error');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('title', selectedUser.title);
      formData.append('description', selectedUser.description);
      formData.append('time', formattedTime);
      formData.append('date', formattedDate);
      formData.append('seats', selectedUser.seats);
      formData.append('mode', selectedUser.mode);
      formData.append('status', selectedUser.status);

      if (imageFile) {
        formData.append('image', imageFile);
      }

      await axios.put(`${apilinkmain}/events/${selectedUser.id}`, formData, {
        withCredentials: true,
        headers: { cnt: cnt },
      });

      Swal.fire('Success', 'Event updated successfully.', 'success');
      fetchEvents(); // Refresh the events list
      setShowDetailPopup(false);
    } catch (error) {
      console.error('Error updating event:', error);
      Swal.fire('Error', 'Failed to update event.', 'error');
    }
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    const lowercasedQuery = event.target.value.toLowerCase();
    setFilteredData(
      data.filter(
        (vendor) =>
          vendor.title.toLowerCase().includes(lowercasedQuery) ||
          vendor.description.toLowerCase().includes(lowercasedQuery)
      )
    );
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
        setImageFile(file);
        const reader = new FileReader();
        reader.onloadend = () => {
            const imageBase64 = reader.result.split(',')[1];
            if (selectedUser) {
                setSelectedUser((prevUser) => ({
                    ...prevUser,
                    image_data: imageBase64,
                }));
            } else {
                setNewVendor((prevVendor) => ({
                    ...prevVendor,
                    image_data: imageBase64,
                }));
            }
        };
        reader.readAsDataURL(file);
    }
};
  return (
    <Box sx={{ padding: 2, overflowY: 'hidden', height: '83vh' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 2,
        }}
      >
        <Typography variant="h5">Events Management</Typography>
      </Box>

      {/* Search, Filter, and Action Buttons */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', width: '40%', marginLeft: '10px', marginTop: '10px' }}>
          <TextField
            label="Search by title "
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={handleSearch}
            sx={{ flexGrow: 1 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', width: '25%', justifyContent: 'flex-end' }}>
          <PremiumButton variant="contained" onClick={handleAddVendorClick} sx={{ marginRight: 1 }}>
            + Add Event
          </PremiumButton>
        </Box>
      </Box>
      

      {/* Table */}
      <TableContainer component={Paper} sx={{ height: '500px' }}>
        <Table>
          <StyledTableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Time</TableCell>
              <TableCell>Seats</TableCell>
              <TableCell>Mode</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {filteredData.map((vendor) => (
              <StyledTableRow key={vendor.id} onClick={() => handleRowClick(vendor)}>
                <StyledTableCell>{vendor.title}</StyledTableCell>
                <StyledTableCell>{vendor.description}</StyledTableCell>
                <StyledTableCell>{vendor.date}</StyledTableCell>
                <StyledTableCell>{vendor.time}</StyledTableCell>
                <StyledTableCell>{vendor.seats}</StyledTableCell>
                <StyledTableCell>{vendor.mode}</StyledTableCell>
                <StyledTableCell>{vendor.status}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Detail Popup */}
      <Dialog open={showDetailPopup} onClose={handleCloseDetailPopup}>
        <DialogContent>
          {selectedUser && (
            <Box p={2}>
              <Typography variant="h6" mb={2}>
                Event Details
              </Typography>
              {selectedUser?.image_data && (
                <Box sx={{ mb: 2, textAlign: 'center' }}>
                  <DetailImage
                    src={`data:image/jpeg;base64,${selectedUser.image_data}`}
                    alt="Uploaded Image"
                  />
                </Box>
              )}
              <input
                type="file"
                accept="image/*"
                onChange={(event) => handleImageChange(event, true)}
                style={{ marginTop: '16px', marginBottom: '16px' }}
              />
              <StyledTextField
                sx={{ mb: 2 }}
                label="Title"
                fullWidth
                value={selectedUser.title}
                onChange={(e) => handleFieldChange('title', e.target.value)}
              />
              <StyledTextArea
                sx={{ mb: 2 }}
                label="Description"
                fullWidth
                multiline
                rows={4}
                value={selectedUser.description}
                onChange={(e) => handleFieldChange('description', e.target.value)}
              />
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <StyledTextField
                  label="Year"
                  value={selectedUser.year}
                  onChange={(e) => handleFieldChange('year', e.target.value)}
                  type="number"
                  inputProps={{ min: 2024, max: 2050 }}
                  sx={{ width: '30%' }}
                />
                <StyledTextField
                  label="Month"
                  value={selectedUser.month}
                  onChange={(e) => handleFieldChange('month', e.target.value)}
                  type="number"
                  inputProps={{ min: 1, max: 12 }}
                  sx={{ width: '30%' }}
                />
                <StyledTextField
                  label="Day"
                  value={selectedUser.day}
                  onChange={(e) => handleFieldChange('day', e.target.value)}
                  type="number"
                  inputProps={{ min: 1, max: 31 }}
                  sx={{ width: '30%' }}
                />
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <StyledTextField
                  label="Hour"
                  value={selectedUser.hour}
                  onChange={(e) => handleFieldChange('hour', e.target.value)}
                  type="number"
                  inputProps={{ min: 1, max: 12 }}
                  sx={{ width: '30%' }}
                />
                <StyledTextField
                  label="Minute"
                  value={selectedUser.minute}
                  onChange={(e) => handleFieldChange('minute', e.target.value)}
                  type="number"
                  inputProps={{ min: 0, max: 59 }}
                  sx={{ width: '30%' }}
                />
                <Select
                  value={selectedUser.period}
                  onChange={(e) => handleFieldChange('period', e.target.value)}
                  sx={{ width: '30%' }}
                >
                  <MenuItem value="AM">AM</MenuItem>
                  <MenuItem value="PM">PM</MenuItem>
                </Select>
              </Box>
              <StyledTextField
                label="Seats"
                fullWidth
                value={selectedUser.seats}
                onChange={(e) => handleFieldChange('seats', e.target.value)}
              />
              <Select
                fullWidth
                value={selectedUser.mode}
                onChange={(e) => handleFieldChange('mode', e.target.value)}
                variant="outlined"
                sx={{ mt: 2 }}
              >
                <MenuItem value="Physical">Physical</MenuItem>
                <MenuItem value="Virtual">Virtual</MenuItem>
              </Select>
              <Select
                fullWidth
                value={selectedUser.status}
                onChange={(e) => handleFieldChange('status', e.target.value)}
                variant="outlined"
                sx={{ mt: 2 }}
              >
                <MenuItem value="enabled">Enabled</MenuItem>
                <MenuItem value="disabled">Disabled</MenuItem>
              </Select>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <PremiumButton variant="contained" onClick={handleUpdateVendor}>
            Update
          </PremiumButton>
          <PremiumButton onClick={handleCloseDetailPopup}>
            Close
          </PremiumButton>
        </DialogActions>
      </Dialog>

      {/* Add Vendor Popup */}
      <Dialog open={showAddVendorPopup} onClose={handleCloseAddVendorPopup}>
        <DialogContent>
          <Box p={2}>
            <Typography variant="h6" mb={2}>
              Add New Event
            </Typography>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{ marginTop: '16px', marginBottom: '16px' }}
            />
            <StyledTextField
              sx={{ mb: 2 }}
              label="Title"
              fullWidth
              value={newVendor.title}
              onChange={(e) => setNewVendor({ ...newVendor, title: e.target.value })}
            />
            <StyledTextArea
              sx={{ mb: 2 }}
              label="Description"
              fullWidth
              multiline
              rows={4}
              value={newVendor.description}
              onChange={(e) => setNewVendor({ ...newVendor, description: e.target.value })}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
              <StyledTextField
                label="Year"
                value={newVendor.year}
                onChange={(e) => setNewVendor({ ...newVendor, year: e.target.value })}
                type="number"
                inputProps={{ min: 2024, max: 2050 }}
                sx={{ width: '30%' }}
              />
              <StyledTextField
                label="Month"
                value={newVendor.month}
                onChange={(e) => setNewVendor({ ...newVendor, month: e.target.value })}
                type="number"
                inputProps={{ min: 1, max: 12 }}
                sx={{ width: '30%' }}
              />
              <StyledTextField
                label="Day"
                value={newVendor.day}
                onChange={(e) => setNewVendor({ ...newVendor, day: e.target.value })}
                type="number"
                inputProps={{ min: 1, max: 31 }}
                sx={{ width: '30%' }}
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
              <StyledTextField
                label="Hour"
                value={newVendor.hour}
                onChange={(e) => setNewVendor({ ...newVendor, hour: e.target.value })}
                type="number"
                inputProps={{ min: 1, max: 12 }}
                sx={{ width: '30%' }}
              />
              <StyledTextField
                label="Minute"
                value={newVendor.minute}
                onChange={(e) => setNewVendor({ ...newVendor, minute: e.target.value })}
                type="number"
                inputProps={{ min: 0, max: 59 }}
                sx={{ width: '30%' }}
              />
              <Select
                value={newVendor.period}
                onChange={(e) => setNewVendor({ ...newVendor, period: e.target.value })}
                sx={{ width: '30%' }}
              >
                <MenuItem value="AM">AM</MenuItem>
                <MenuItem value="PM">PM</MenuItem>
              </Select>
            </Box>
            <StyledTextField
              label="Seats"
              fullWidth
              value={newVendor.seats}
              onChange={(e) => setNewVendor({ ...newVendor, seats: e.target.value })}
            />
            <Select
              fullWidth
              value={newVendor.mode}
              onChange={(e) => setNewVendor({ ...newVendor, mode: e.target.value })}
              variant="outlined"
              sx={{ mt: 2 }}
            >
              <MenuItem value="Physical">Physical</MenuItem>
              <MenuItem value="Virtual">Virtual</MenuItem>
            </Select>
            <Select
              fullWidth
              value={newVendor.status}
              onChange={(e) => setNewVendor({ ...newVendor, status: e.target.value })}
              variant="outlined"
              sx={{ mt: 2 }}
            >
              <MenuItem value="enabled">Enabled</MenuItem>
              <MenuItem value="disabled">Disabled</MenuItem>
            </Select>
          </Box>
        </DialogContent>
        <DialogActions>
          <PremiumButton variant="contained" onClick={handleSubmitNewVendor}>
            Add Event
          </PremiumButton>
          <PremiumButton onClick={handleCloseAddVendorPopup}>
            Close
          </PremiumButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Events;
