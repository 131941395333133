import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Zoom,
  MenuItem,
  IconButton,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';
import { styled } from '@mui/system';
import { Search } from '@mui/icons-material';
import Swal from 'sweetalert2';
import { apilinkmain } from '../api';
import icon from '../img/bell.png';

// Define theme color (Original Theme)
const themeColor = {
  primary: '#444',
  primaryDark: '#666',
  success: '#4caf50',
  error: '#f44336',
  headerBg: '#444',
  headerTextColor: '#ffffff',
  borderColor: '#777',
  color: '#000000',
  rowHoverColor: '#ebebeb',
  rowAlternateColor: '#f5f5f5',
  rowHoverHighlight: '#e0f7fa',
};

// Define styled components (Original Theme)
const PremiumButton = styled(Button)(({ variant }) => ({
  borderRadius: '6px',
  padding: '6px 12px',
  textTransform: 'none',
  fontWeight: 'bold',
  height: '30px',
  minWidth: '80px',
  fontSize: '12px',
  transition: 'background-color 0.3s ease, transform 0.3s ease',
  cursor: 'pointer',
  border: 'none',
  boxShadow: `0 3px 6px rgba(0, 0, 0, 0.1)`,
  background: variant === 'contained' ? themeColor.primary : 'transparent',
  color: variant === 'contained' ? '#fff' : themeColor.primary,
  border: variant === 'outlined' ? `1px solid ${themeColor.primary}` : 'none',
  '&:hover': {
    backgroundColor: variant === 'contained' ? themeColor.primaryDark : 'rgba(51, 51, 51, 0.05)',
    transform: 'scale(1.03)',
  },
  '&:active': {
    transform: 'scale(0.97)',
  },
}));

const StyledTableCell = styled(TableCell)(() => ({
  fontSize: '12px',
  borderBottom: `1px solid ${themeColor.borderColor}`,
  padding: '6px 8px',
  textAlign: 'center',
  color: themeColor.color,
}));

const StyledTableRow = styled(TableRow)(() => ({
  cursor: 'pointer',
  height: '40px',
  transition: 'background-color 0.3s ease',
  '&:nth-of-type(odd)': {
    backgroundColor: themeColor.rowAlternateColor,
  },
  '&:hover': {
    backgroundColor: themeColor.rowHoverHighlight,
    boxShadow: `0px 2px 4px rgba(0,0,0,0.05)`,
  },
}));

const StyledTableHead = styled(TableHead)(() => ({
  backgroundColor: themeColor.primary,
  color: themeColor.headerTextColor,
  position: 'sticky',
  top: 0,
  zIndex: 2,
  '& th': {
    fontSize: '13px',
    fontWeight: 'bold',
    padding: '10px 12px',
    textAlign: 'center',
    borderRight: `1px solid ${themeColor.borderColor}`,
    background: themeColor.primary,
    color: themeColor.headerTextColor,
    '&:last-child': {
      borderRight: 'none',
    },
  },
}));

const TitleTypography = styled(Typography)(() => ({
  fontWeight: 'bold',
  color: themeColor.primary,
  fontSize: '18px',
  marginBottom: '16px',
  textAlign: 'center',
  width: '50%',
  padding: '6px 0',
  marginLeft: 'auto',
  marginRight: 'auto',
  borderRadius: '6px',
  position: 'sticky',
  top: 0,
  zIndex: 3,
}));

const DetailTableCell = styled(TableCell)(() => ({
  borderBottom: `1px solid ${themeColor.borderColor}`,
  padding: '8px',
  fontSize: '12px',
  color: themeColor.color,
  '&:first-of-type': {
    fontWeight: 'bold',
    color: themeColor.primaryDark,
  },
}));

const Contact = () => {
  const [data, setData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showDetailPopup, setShowDetailPopup] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [newMessageCount, setNewMessageCount] = useState(0);
  const previousDataRef = useRef([]);

  // Check if browser supports notifications
  const checkNotificationPermission = () => {
    if (Notification.permission === 'default') {
      Notification.requestPermission();
    }
  };

  useEffect(() => {
    checkNotificationPermission();
  }, []);

  const showDesktopNotification = (count) => {
    if (Notification.permission === 'granted') {
      const notification = new Notification('New Contact Us Messages', {
        body: `You have ${count} new message${count > 1 ? 's' : ''}.`,
        icon: icon, // Optional icon path
      });

      notification.onclick = () => {
        window.focus();
      };
    }
  };

  // Fetch data from the backend
  const fetchMessages = async (showNotification = false) => {
    const cnt = localStorage.getItem('cnt');
    try {
      const response = await axios.get(`${apilinkmain}/messages`, {
        withCredentials: true,
        headers: { cnt: cnt },
      });
      const fetchedData = response.data;

      // Calculate new messages
      const newMessages = fetchedData.filter((msg) => msg.statusid === 'new');
      const newCount = newMessages.length;

      // Check if there's a change in the count of new messages
      if (showNotification && newCount > newMessageCount) {
        const diff = newCount - newMessageCount;
        
        // Show system-level notification
        showDesktopNotification(diff);
      }

      setData(fetchedData);
      setNewMessageCount(newCount);
    } catch (error) {
      console.error('Error fetching messages:', error);
      Swal.fire('Error', 'Failed to fetch messages data.', 'error');
    }
  };

  useEffect(() => {
    fetchMessages();

    // Poll for new messages every 3 minutes (180000 ms)
    const intervalId = setInterval(() => {
      fetchMessages(true); // Pass true to show notification if there are new messages
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const handleRowClick = (user) => {
    setSelectedUser(user);
    setShowDetailPopup(true);
  };

  const handleCloseDetailPopup = () => {
    setShowDetailPopup(false);
    setSelectedUser(null);
  };

  const handleReadClick = async () => {
    setShowDetailPopup(false);
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to mark this message as read?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: themeColor.success,
      cancelButtonColor: themeColor.error,
      confirmButtonText: 'Yes, mark as read',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const formData = new FormData();
          formData.append('status', 'read');

          await axios.put(`${apilinkmain}/messages/${selectedUser.id}/status`, formData, {
            withCredentials: true,
          });

          setData((prevData) =>
            prevData.map((item) =>
              item.id === selectedUser.id ? { ...item, status: 'read' } : item
            )
          );
          fetchMessages();
          Swal.fire('Updated!', 'The message has been marked as read.', 'success');
        } catch (error) {
          console.error('Error updating message status:', error);
          Swal.fire('Error', 'Failed to update message status.', 'error');
        }
      }
    });
  };

  const filteredData = data.filter(
    (user) =>
      (user.firstname.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.company.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (filterStatus === '' || user.statusid === filterStatus)
  );

  return (
    <Box sx={{ padding: 2, overflowY: 'hidden', height: '83vh' }}>
      <Typography variant="h5">Contact Us Message Box</Typography>
      {/* Search, Filter, and Action Buttons */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', width: '40%', marginLeft: '10px', marginTop: '10px' }}>
          <TextField
            label="Search by Company Name"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ flexGrow: 1 }}
            InputProps={{
              startAdornment: (
                <IconButton>
                  <Search />
                </IconButton>
              ),
            }}
          />
        </Box>
        <FormControl variant="outlined" size="small" sx={{ width: '20%' }}>
          <InputLabel>Status</InputLabel>
          <Select
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
            label="Status"
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            <MenuItem value="new">New</MenuItem>
            <MenuItem value="read">Read</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <TableContainer
        component={Paper}
        sx={{
          overflow: 'auto',
          height: '500px',
          marginBottom: '20px',
          borderRadius: '8px',
          boxShadow: '0 3px 6px rgba(0,0,0,0.1)',
        }}
      >
        <Table stickyHeader>
          <StyledTableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Company</StyledTableCell>
              <StyledTableCell>Contact NO</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Message</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {filteredData.map((user) => (
              <StyledTableRow key={user.id} onClick={() => handleRowClick(user)}>
                <StyledTableCell>{`${user.firstname} ${user.lastname}`}</StyledTableCell>
                <StyledTableCell>{user.company}</StyledTableCell>
                <StyledTableCell>{user.tpno}</StyledTableCell>
                <StyledTableCell>{user.email}</StyledTableCell>
                <StyledTableCell>{user.comment}</StyledTableCell>
                <StyledTableCell>{user.statusid}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Detail Popup */}
      <Dialog
        open={showDetailPopup}
        onClose={handleCloseDetailPopup}
        TransitionComponent={Zoom}
        transitionDuration={300}
        maxWidth="sm"
        fullWidth
      >
        <TitleTypography>Message Details</TitleTypography>
        <DialogContent dividers>
          {selectedUser && (
            <Box>
              <Table>
                <TableBody>
                  <TableRow>
                    <DetailTableCell>Name:</DetailTableCell>
                    <DetailTableCell>{`${selectedUser.firstname} ${selectedUser.lastname}`}</DetailTableCell>
                  </TableRow>
                  <TableRow>
                    <DetailTableCell>Company:</DetailTableCell>
                    <DetailTableCell>{selectedUser.company}</DetailTableCell>
                  </TableRow>
                  <TableRow>
                    <DetailTableCell>Contact NO:</DetailTableCell>
                    <DetailTableCell>{selectedUser.tpno}</DetailTableCell>
                  </TableRow>
                  <TableRow>
                    <DetailTableCell>Email:</DetailTableCell>
                    <DetailTableCell>{selectedUser.email}</DetailTableCell>
                  </TableRow>
                  <TableRow>
                    <DetailTableCell>Message:</DetailTableCell>
                    <DetailTableCell>{selectedUser.comment}</DetailTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          {/* Conditionally render Mark as Read button based on message status */}
          {selectedUser && selectedUser.statusid === 'new' && (
            <PremiumButton onClick={handleReadClick} variant="contained">
              Mark as Read
            </PremiumButton>
          )}
          <PremiumButton onClick={handleCloseDetailPopup} variant="outlined">
            Close
          </PremiumButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Contact;
