import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Box,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    InputAdornment,
    Paper,
    Select,
    MenuItem,
    Zoom,
    CircularProgress,
} from '@mui/material';
import { styled } from '@mui/system';
import Swal from 'sweetalert2';
import { Search } from '@mui/icons-material';
import { apilinkmain } from '../api';

const themeColor = {
    primary: '#444',
    primaryDark: '#666',
    success: '#4caf50',
    error: '#f44336',
    headerBg: '#444',
    headerTextColor: '#ffffff',
    borderColor: '#777',
    color: '#000000',
    rowHoverColor: '#ebebeb',
    rowAlternateColor: '#f5f5f5',
    rowHoverHighlight: '#e0f7fa',
};

// Styled components
const PremiumButton = styled(Button)(({ variant }) => ({
    borderRadius: '6px',
    padding: '6px 12px',
    textTransform: 'none',
    fontWeight: 'bold',
    height: '30px',
    minWidth: '80px',
    fontSize: '12px',
    transition: 'background-color 0.3s ease, transform 0.3s ease',
    cursor: 'pointer',
    border: 'none',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)',
    background: variant === 'contained' ? themeColor.primary : 'transparent',
    color: variant === 'contained' ? '#fff' : themeColor.primary,
    border: variant === 'outlined' ? `1px solid ${themeColor.primary}` : 'none',
    '&:hover': {
        backgroundColor: variant === 'contained' ? themeColor.primaryDark : 'rgba(51, 51, 51, 0.05)',
        transform: 'scale(1.03)',
    },
    '&:active': {
        transform: 'scale(0.97)',
    },
}));

const StyledTableCell = styled(TableCell)({
    fontSize: '12px',
    borderBottom: `1px solid ${themeColor.borderColor}`,
    padding: '6px 8px',
    textAlign: 'center',
    color: themeColor.color,
});

const StyledTableRow = styled(TableRow)({
    cursor: 'pointer',
    height: '40px',
    transition: 'background-color 0.3s ease',
    '&:nth-of-type(odd)': {
        backgroundColor: '#ffffff',
    },
    '&:hover': {
        backgroundColor: themeColor.rowHoverHighlight,
        boxShadow: '0px 2px 4px rgba(0,0,0,0.05)',
    },
});

const StyledTableHead = styled(TableHead)({
    backgroundColor: themeColor.primary,
    color: themeColor.headerTextColor,
    position: 'sticky',
    top: 0,
    zIndex: 2,
    '& th': {
        fontSize: '13px',
        fontWeight: 'bold',
        padding: '10px 12px',
        textAlign: 'center',
        borderRight: `1px solid ${themeColor.borderColor}`,
        background: themeColor.primary,
        color: themeColor.headerTextColor,
        '&:last-child': {
            borderRight: 'none',
        },
    },
});

const StyledTextField = styled(TextField)({
    '& .MuiInputBase-root': {
        fontSize: '14px',
    },
});

const StyledTextArea = styled(TextField)({
    '& .MuiInputBase-root': {
        fontSize: '14px',
    },
});

const DetailImage = styled('img')({
    width: '70%',
    height: 'auto',
    borderRadius: '8px',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
    marginBottom: '16px',
});

const DetailTableCell = styled(TableCell)({
    borderBottom: `1px solid ${themeColor.borderColor}`,
    padding: '8px',
    fontSize: '12px',
    color: themeColor.color,
    '&:first-of-type': {
        fontWeight: 'bold',
        color: themeColor.primaryDark,
    },
});

const Vendormgt = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [loading, setLoading] = useState(true); // State for loading
    const [error, setError] = useState(null); // State for error
    const [showDetailPopup, setShowDetailPopup] = useState(false);
    const [showAddVendorPopup, setShowAddVendorPopup] = useState(false);
    const [newVendor, setNewVendor] = useState({
        title: '',
        firstText: '',
        description: '',
        image_data: null,
        statusid: 'enabled',
    });
    const [searchQuery, setSearchQuery] = useState('');
    const [imageFile, setImageFile] = useState(null);

    const fetchVendors = async () => {
        setLoading(true); // Start loading
        const cnt = localStorage.getItem('cnt');
        try {
            const response = await axios.get(`${apilinkmain}/blogs`, {
                withCredentials: true,
                headers: { cnt: cnt }
            });
            setData(response.data);
            setFilteredData(response.data);
        } catch (error) {
            console.error('Error fetching vendors:', error);
            Swal.fire('Error', 'Failed to fetch vendor data.', 'error');
        } finally {
            setLoading(false); // End loading
        }
    };

    useEffect(() => {
        fetchVendors();
    }, []);

    const handleRowClick = (user) => {
        setSelectedUser(user);
        setShowDetailPopup(true);
    };

    const handleCloseDetailPopup = () => {
        setShowDetailPopup(false);
        setSelectedUser(null);
    };

    const handleAddVendorClick = () => {
        setShowAddVendorPopup(true);
    };

    const handleCloseAddVendorPopup = () => {
        setShowAddVendorPopup(false);
    };

    const handleSubmitNewVendor = async () => {
        const cnt = localStorage.getItem('cnt');

        if (!newVendor.title || !newVendor.firstText || !newVendor.description) {
            Swal.fire('Validation Error', 'Please fill out all required fields.', 'error');
            return;
        }

        const formData = new FormData();
        formData.append('title', newVendor.title);
        formData.append('firstText', newVendor.firstText);
        formData.append('description', newVendor.description);
        formData.append('statusid', newVendor.statusid);
        formData.append('cnt', cnt);

        if (imageFile) {
            formData.append('image', imageFile);
        }

        try {
            const response = await axios.post(`${apilinkmain}/blogs`, formData, {
                withCredentials: true,
                headers: { cnt: cnt }
            });

            const newVendorData = { ...newVendor, id: response.data.newsId };
            setData([...data, newVendorData]);
            setFilteredData([...data, newVendorData]);
            setShowAddVendorPopup(false);
            setNewVendor({
                title: '',
                firstText: '',
                description: '',
                image_data: null,
                statusid: 'enabled',
            });

            Swal.fire('Success', 'New blog has been added.', 'success').then(fetchVendors);
        } catch (error) {
            console.error('Error adding blog:', error);
            Swal.fire('Error', 'Failed to add blog.', 'error');
        }
    };

    const handleUpdateVendor = async () => {
        const cnt = localStorage.getItem('cnt');

        const formData = new FormData();
        formData.append('title', selectedUser.title);
        formData.append('firstText', selectedUser.firstText);
        formData.append('description', selectedUser.description);
        formData.append('statusid', selectedUser.statusid);

        if (imageFile) {
            formData.append('image', imageFile);
        }

        try {
            await axios.put(`${apilinkmain}/blogs/${selectedUser.id}`, formData, {
                withCredentials: true,
                headers: { cnt: cnt }
            });

            const updatedData = data.map((vendor) =>
                vendor.id === selectedUser.id ? { ...selectedUser } : vendor
            );

            setData(updatedData);
            setFilteredData(updatedData);
            setShowDetailPopup(false);
            Swal.fire('Success', 'Blog updated successfully.', 'success').then(fetchVendors);
        } catch (error) {
            setShowDetailPopup(false);
            console.error('Error updating blog:', error);
            Swal.fire('Error', 'Failed to update blog.', 'error');
        }
    };

    const handleSearchChange = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);
        setFilteredData(
            data.filter((vendor) =>
                vendor.title.toLowerCase().includes(query)
            )
        );
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImageFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                const imageBase64 = reader.result.split(',')[1];
                if (selectedUser) {
                    setSelectedUser((prevUser) => ({
                        ...prevUser,
                        image_data: imageBase64,
                    }));
                } else {
                    setNewVendor((prevVendor) => ({
                        ...prevVendor,
                        image_data: imageBase64,
                    }));
                }
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <Box sx={{ padding: 2, overflowY: 'hidden', height: '83vh' }}>
            <Typography variant="h5">Blog Management</Typography>

            {/* Search, Filter, and Action Buttons */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', width: '40%', marginLeft: '10px', marginTop: '10px' }}>
                    <TextField
                        label="Search by title"
                        variant="outlined"
                        size="small"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        sx={{ flexGrow: 1 }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', width: '25%', justifyContent: 'flex-end' }}>
                    <PremiumButton variant="contained" onClick={handleAddVendorClick} sx={{ marginRight: 1 }}>
                        + Add Blog
                    </PremiumButton>
                </Box>
            </Box>

            {/* Loading Indicator */}
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
                    <CircularProgress />
                </Box>
            ) : (
                // Table displaying the data
                <TableContainer
                    component={Paper}
                    sx={{
                        overflow: 'auto',
                        height: '500px',
                        marginBottom: '20px',
                        borderRadius: '8px',
                        boxShadow: '0 3px 6px rgba(0,0,0,0.1)',
                        width: '100%',
                        margin: '0 auto',
                    }}
                >
                    <Table stickyHeader>
                        <StyledTableHead>
                            <TableRow>
                                <StyledTableCell>Blog ID</StyledTableCell>
                                <StyledTableCell>Title</StyledTableCell>
                                <StyledTableCell>Short Description</StyledTableCell>
                                <StyledTableCell>Long Description</StyledTableCell>
                                <StyledTableCell>Status</StyledTableCell>
                            </TableRow>
                        </StyledTableHead>
                        <TableBody>
                            {filteredData.map((vendor) => (
                                <StyledTableRow
                                    key={vendor.id}
                                    onClick={() => handleRowClick(vendor)}
                                >
                                    <StyledTableCell>{vendor.id}</StyledTableCell>
                                    <StyledTableCell>{vendor.title}</StyledTableCell>
                                    <StyledTableCell>{vendor.firstText}</StyledTableCell>
                                    <StyledTableCell>{vendor.description}</StyledTableCell>
                                    <StyledTableCell>{vendor.statusid}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {/* Detail Popup */}
            <Dialog
                open={showDetailPopup}
                onClose={handleCloseDetailPopup}
                PaperProps={{
                    style: {
                        width: '100vh',
                        borderRadius: '8px',
                        boxShadow: '0px 3px 6px rgba(0,0,0,0.2)',
                    },
                }}
                TransitionComponent={Zoom}
                TransitionProps={{ timeout: 400 }}
            >
                <DialogContent>
                    <Typography variant="h6" mb={2}>
                        Update Blog
                    </Typography>
                    {selectedUser && (
                        <>
                            {selectedUser?.image_data && (
                                <Box sx={{ mb: 2, textAlign: 'center' }}>
                                    <DetailImage
                                        src={`data:image/jpeg;base64,${selectedUser.image_data}`}
                                        alt="Uploaded Image"
                                    />
                                </Box>
                            )}
                            <StyledTextField
                                label="Title"
                                fullWidth
                                margin="normal"
                                value={selectedUser.title}
                                onChange={(e) =>
                                    setSelectedUser({
                                        ...selectedUser,
                                        title: e.target.value,
                                    })
                                }
                            />
                            <StyledTextArea
                                label="Short Description"
                                fullWidth
                                margin="normal"
                                value={selectedUser.firstText}
                                onChange={(e) =>
                                    setSelectedUser({
                                        ...selectedUser,
                                        firstText: e.target.value,
                                    })
                                }
                            />
                            <StyledTextArea
                                label="Long Description"
                                fullWidth
                                margin="normal"
                                multiline
                                rows={4}
                                value={selectedUser.description}
                                onChange={(e) =>
                                    setSelectedUser({
                                        ...selectedUser,
                                        description: e.target.value,
                                    })
                                }
                            />
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <DetailTableCell>Status:</DetailTableCell>
                                        <DetailTableCell>
                                            <Select
                                                fullWidth
                                                value={selectedUser.statusid}
                                                onChange={(e) =>
                                                    setSelectedUser({
                                                        ...selectedUser,
                                                        statusid: e.target.value,
                                                    })
                                                }
                                            >
                                                <MenuItem value="enabled">Enabled</MenuItem>
                                                <MenuItem value="disabled">Disabled</MenuItem>
                                            </Select>
                                        </DetailTableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <input
                                label="Upload Image"
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                style={{ marginTop: '16px', marginBottom: '16px' }}
                            />
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <PremiumButton variant='contained' onClick={handleUpdateVendor} color="primary">
                        Update
                    </PremiumButton>
                    <PremiumButton onClick={handleCloseDetailPopup} color="secondary">
                        Cancel
                    </PremiumButton>
                </DialogActions>
            </Dialog>

            {/* Add Vendor Popup */}
            <Dialog
                open={showAddVendorPopup}
                onClose={handleCloseAddVendorPopup}
                PaperProps={{
                    style: {
                        width: '100vh',
                        borderRadius: '8px',
                        boxShadow: '0px 3px 6px rgba(0,0,0,0.2)',
                    },
                }}
                TransitionComponent={Zoom}
                TransitionProps={{ timeout: 400 }}
            >
                <DialogContent>
                    <Typography variant="h6" mb={2}>
                        Add New Blog
                    </Typography>
                    <StyledTextField
                        label="Title"
                        fullWidth
                        margin="normal"
                        value={newVendor.title}
                        onChange={(e) =>
                            setNewVendor({
                                ...newVendor,
                                title: e.target.value,
                            })
                        }
                    />
                    <StyledTextArea
                        label="Short Description"
                        fullWidth
                        margin="normal"
                        value={newVendor.firstText}
                        onChange={(e) =>
                            setNewVendor({
                                ...newVendor,
                                firstText: e.target.value,
                            })
                        }
                    />
                    <StyledTextArea
                        label="Long Description"
                        fullWidth
                        margin="normal"
                        multiline
                        rows={4}
                        value={newVendor.description}
                        onChange={(e) =>
                            setNewVendor({
                                ...newVendor,
                                description: e.target.value,
                            })
                        }
                    />
                    <input
                        label="Upload Image"
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        style={{ marginTop: '16px', marginBottom: '16px' }}
                    />
                    {newVendor.image_data && (
                        <DetailImage
                            sx={{ width: '50%', marginLeft: '25%' }}
                            src={`data:image/jpeg;base64,${newVendor.image_data}`}
                            alt="Vendor Preview"
                        />
                    )}
                    <Table>
                        <TableBody>
                            <TableRow>
                                <DetailTableCell>Status:</DetailTableCell>
                                <DetailTableCell>
                                    <Select
                                        fullWidth
                                        value={newVendor.statusid}
                                        onChange={(e) =>
                                            setNewVendor({
                                                ...newVendor,
                                                statusid: e.target.value,
                                            })
                                        }
                                    >
                                        <MenuItem value="enabled">Enabled</MenuItem>
                                        <MenuItem value="disabled">Disabled</MenuItem>
                                    </Select>
                                </DetailTableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <PremiumButton variant='contained' onClick={handleSubmitNewVendor} color="primary">
                        Submit
                    </PremiumButton>
                    <PremiumButton onClick={handleCloseAddVendorPopup} color="secondary">
                        Cancel
                    </PremiumButton>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Vendormgt;
