import React, { useState, useEffect } from 'react';
import {
    Box, Typography, Button, Dialog, DialogActions, DialogContent, TextField,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    Select, IconButton, MenuItem, CircularProgress, InputAdornment
} from '@mui/material';
import { styled } from '@mui/system';
import Swal from 'sweetalert2';
import { Search } from '@mui/icons-material';
import axios from 'axios';
import { apilinkmain } from '../api';

// Theme and Styled Components
const themeColor = {
    primary: '#444',
    primaryDark: '#666',
    success: '#4caf50',
    error: '#f44336',
    headerBg: '#444',
    headerTextColor: '#ffffff',
    borderColor: '#777',
    color: '#000000',
    rowHoverColor: '#ebebeb',
    rowAlternateColor: '#f5f5f5',
    rowHoverHighlight: '#e0f7fa',
};

const newsTypeMapping = {
    2: 'latest',
    3: 'normal',
   
};

const PremiumButton = styled(Button)(({ variant }) => ({
    borderRadius: '6px',
    padding: '6px 12px',
    textTransform: 'none',
    fontWeight: 'bold',
    height: '30px',
    minWidth: '80px',
    fontSize: '12px',
    transition: 'background-color 0.3s ease, transform 0.3s ease',
    cursor: 'pointer',
    border: 'none',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)',
    background: variant === 'contained' ? themeColor.primary : 'transparent',
    color: variant === 'contained' ? '#fff' : themeColor.primary,
    border: variant === 'outlined' ? `1px solid ${themeColor.primary}` : 'none',
    '&:hover': {
        backgroundColor: variant === 'contained' ? themeColor.primaryDark : 'rgba(51, 51, 51, 0.05)',
        transform: 'scale(1.03)',
    },
    '&:active': {
        transform: 'scale(0.97)',
    },
}));

const StyledTableCell = styled(TableCell)({
    fontSize: '12px',
    borderBottom: `1px solid ${themeColor.borderColor}`,
    padding: '6px 8px',
    textAlign: 'center',
    color: themeColor.color,
});

const StyledTableRow = styled(TableRow)({
    cursor: 'pointer',
    height: '40px',
    transition: 'background-color 0.3s ease',
    '&:nth-of-type(odd)': {
        backgroundColor: '#ffffff',
    },
    '&:hover': {
        backgroundColor: themeColor.rowHoverHighlight,
        boxShadow: '0px 2px 4px rgba(0,0,0,0.05)',
    },
});

const StyledTableHead = styled(TableHead)({
    backgroundColor: themeColor.primary,
    color: themeColor.headerTextColor,
    position: 'sticky',
    top: 0,
    zIndex: 2,
    '& th': {
        fontSize: '13px',
        fontWeight: 'bold',
        padding: '10px 12px',
        textAlign: 'center',
        borderRight: `1px solid ${themeColor.borderColor}`,
        background: themeColor.primary,
        color: themeColor.headerTextColor,
        '&:last-child': {
            borderRight: 'none',
        },
    },
});

const StyledTextField = styled(TextField)({
    '& .MuiInputBase-root': {
        fontSize: '14px',
    },
});

const StyledTextArea = styled(TextField)({
    '& .MuiInputBase-root': {
        fontSize: '14px',
    },
});

const DetailImage = styled('img')({
    width: '70%',
    height: 'auto',
    borderRadius: '8px',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
    marginBottom: '16px',
});

const VideoPreview = styled('iframe')({
    width: '100%',
    height: '200px',
    border: 'none',
    borderRadius: '8px',
    marginBottom: '16px',
});

const Vendormgt = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true); // State for loading
    const [error, setError] = useState(null); // State for error
    const [selectedUser, setSelectedUser] = useState(null);
    const [showDetailPopup, setShowDetailPopup] = useState(false);
    const [showAddVendorPopup, setShowAddVendorPopup] = useState(false);
    const [newVendor, setNewVendor] = useState({
        title: '',
        link: '',
        image_data: null,
        type: '',
        status: 'enabled',
    });
    const [searchQuery, setSearchQuery] = useState('');
    const [imageFile, setImageFile] = useState(null);

    const fetchNews = async () => {
        const cnt = localStorage.getItem('cnt');
        try {
            setLoading(true); // Start loading
            const response = await axios.get(`${apilinkmain}/news`, {
                withCredentials: true,
                headers: { cnt: cnt }
            });
            setData(response.data);
            setFilteredData(response.data);
        } catch (error) {
            console.error('Error fetching news:', error);
            setError('Failed to fetch news data.');
            Swal.fire('Error', 'Failed to fetch news data.', 'error');
        } finally {
            setLoading(false); // Stop loading
        }
    };

    useEffect(() => {

        fetchNews();
    }, []);

    const handleRowClick = (user) => {
        setSelectedUser(user);
        setShowDetailPopup(true);
    };

    const handleCloseDetailPopup = () => {
        setShowDetailPopup(false);
        setSelectedUser(null);
    };

    const handleAddVendorClick = () => {
        setShowAddVendorPopup(true);
    };

    const handleCloseAddVendorPopup = () => {
        setShowAddVendorPopup(false);
    };

    const handleSubmitNewVendor = async () => {
        const cnt = localStorage.getItem('cnt');

        if (!newVendor.title || !newVendor.link || !newVendor.type) {
            Swal.fire('Validation Error', 'Please fill out all required fields.', 'error');
            return;
        }

        const formData = new FormData();
        formData.append('title', newVendor.title);
        formData.append('link', newVendor.link);
        formData.append('type', newVendor.type);
        formData.append('status', newVendor.status);
        formData.append('cnt', cnt);

        if (imageFile) {
            formData.append('image', imageFile);
        }

        try {
            const response = await axios.post(`${apilinkmain}/news`, formData, {
                withCredentials: true,
                headers: { cnt: cnt }
            });

            const newVendorData = { ...newVendor, id: response.data.newsId };
            setData([...data, newVendorData]);
            setFilteredData([...data, newVendorData]);
            setShowAddVendorPopup(false);
            setNewVendor({
                title: '',
                link: '',
                image_data: null,
                type: 'latest',
                status: 'enabled',
            });

            fetchNews();
            Swal.fire('Success', 'New news has been added.', 'success').then(() => {

                fetchNews();
            });
        } catch (error) {
            console.error('Error adding news:', error);
            Swal.fire('Error', 'Failed to add news.', 'error');
        }
    };

    const handleUpdateVendor = async () => {
        const cnt = localStorage.getItem('cnt');

        const formData = new FormData();
        formData.append('title', selectedUser.title);
        formData.append('link', selectedUser.link);
        formData.append('type', selectedUser.type);
        formData.append('status', selectedUser.status);

        if (imageFile) {
            formData.append('image', imageFile);
        }

        try {
            await axios.put(`${apilinkmain}/news/${selectedUser.id}`, formData, {
                withCredentials: true,
                headers: { cnt: cnt }
            });

            const updatedData = data.map((vendor) =>
                vendor.id === selectedUser.id ? { ...selectedUser } : vendor
            );

            setData(updatedData);
            setFilteredData(updatedData);
            setShowDetailPopup(false);
            fetchNews();
            Swal.fire('Success', 'News updated successfully.', 'success').then(() => {

                fetchNews();
            });

        } catch (error) {
            setShowDetailPopup(false);
            console.error('Error updating news:', error);
            Swal.fire('Error', 'Failed to update news.', 'error');
        }
    };

    const handleSearchChange = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);
        setFilteredData(
            data.filter((vendor) =>
                vendor.title.toLowerCase().includes(query)
            )
        );
    };

    const forceUpdate = useState()[1].bind(null, {});

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImageFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedUser((prevUser) => ({
                    ...prevUser,
                    image_data: reader.result.split(',')[1],
                    image: file,
                }));
                setNewVendor((prevVendor) => ({
                    ...prevVendor,
                    image_data: reader.result.split(',')[1], // Store Base64 data without prefix
                }));
                forceUpdate();
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <Box sx={{ padding: 2, overflowY: 'hidden', height: '83vh' }}>
            <Typography variant="h5">News Management</Typography>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', width: '40%', marginLeft: '10px', marginTop: '10px' }}>
                    <TextField
                        label="Search by title"
                        variant="outlined"
                        size="small"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        sx={{ flexGrow: 1 }}
                        InputProps={{
                            startAdornment: (
                                <IconButton>
                                    <Search />
                                </IconButton>
                            ),
                        }}
                    />
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', width: '25%', justifyContent: 'flex-end' }}>
                    <PremiumButton variant="contained" onClick={handleAddVendorClick} sx={{ marginRight: 1 }}>
                        + Add News
                    </PremiumButton>
                </Box>
            </Box>

            {/* Loading State */}
            {loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
                    <CircularProgress />
                </Box>
            )}

            {/* Error State */}
            {error && (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
                    <Typography variant="h6" color="error">
                        {error}
                    </Typography>
                </Box>
            )}

            {/* Main Content */}
            {!loading && !error && (
                <TableContainer
                    component={Paper}
                    sx={{
                        overflow: 'auto',
                        height: '400px',
                        marginBottom: '20px',
                        borderRadius: '8px',
                        boxShadow: '0 3px 6px rgba(0,0,0,0.1)',
                        '&::-webkit-scrollbar': {
                            width: '6px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: themeColor.primaryDark,
                            borderRadius: '4px',
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: themeColor.primaryLight,
                        },
                    }}
                >
                    <Table>
                        <StyledTableHead>
                            <TableRow>
                                <StyledTableCell>ID</StyledTableCell>
                                <StyledTableCell>Title</StyledTableCell>
                                <StyledTableCell>News Type</StyledTableCell>
                                <StyledTableCell>Status</StyledTableCell>
                            </TableRow>
                        </StyledTableHead>
                        <TableBody>
                            {filteredData.map((vendor) => (
                                <StyledTableRow key={vendor.id} onClick={() => handleRowClick(vendor)}>
                                    <StyledTableCell>{vendor.id}</StyledTableCell>
                                    <StyledTableCell>{vendor.title}</StyledTableCell>
                                    <StyledTableCell>{newsTypeMapping[vendor.type]}</StyledTableCell>
                                    <StyledTableCell>{vendor.status}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {/* Detail Popup */}
            <Dialog open={showDetailPopup} onClose={handleCloseDetailPopup} maxWidth="sm" fullWidth>
                <DialogContent>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mb: 2,
                        }}
                    >
                        <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
                            Edit News Details
                        </Typography>
                        {selectedUser?.link && (
                            <>
                                <VideoPreview
                                    src={selectedUser.link}
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                />
                            </>
                        )}

                        <StyledTextField
                            label="Title"
                            fullWidth
                            value={selectedUser?.title || ''}
                            onChange={(e) =>
                                setSelectedUser({ ...selectedUser, title: e.target.value })
                            }
                            sx={{ mb: 2 }}
                        />
                        <StyledTextField
                            label="Video Link"
                            fullWidth
                            value={selectedUser?.link || ''}
                            onChange={(e) =>
                                setSelectedUser({ ...selectedUser, link: e.target.value })
                            }
                            sx={{ mb: 2 }}
                        />

                        <Box sx={{ mb: 2 }}>
                            <input
                                accept="image/*"
                                style={{ display: 'none' }}
                                id="upload-image"
                                type="file"
                                onChange={handleImageChange}
                            />
                            <label htmlFor="upload-image">
                                <Button variant="outlined" color="primary" component="span">
                                    Upload Image
                                </Button>
                            </label>
                        </Box>
                        {selectedUser?.image_data && (
                            <Box sx={{ mb: 2, textAlign: 'center' }}>
                                <DetailImage
                                    src={`data:image/jpeg;base64,${selectedUser.image_data}`}
                                    alt="Uploaded Image"
                                />
                            </Box>
                        )}

                        <StyledTextField
                            select
                            label="News Type"
                            fullWidth
                            value={selectedUser?.type || ''}
                            onChange={(e) =>
                                setSelectedUser({ ...selectedUser, type: e.target.value })
                            }
                            sx={{ mb: 2 }}
                        >
                            
                            <MenuItem value="2">Latest</MenuItem>
                            <MenuItem value="3">Normal</MenuItem>
                         
                          
                        </StyledTextField>
                        <StyledTextField
                            select
                            label="Status"
                            fullWidth
                            value={selectedUser?.status || ''}
                            onChange={(e) =>
                                setSelectedUser({ ...selectedUser, status: e.target.value })
                            }
                        >
                            <MenuItem value="enabled">Enabled</MenuItem>
                            <MenuItem value="disabled">Disabled</MenuItem>
                        </StyledTextField>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <PremiumButton onClick={handleCloseDetailPopup} variant="outlined">
                        Close
                    </PremiumButton>
                    <PremiumButton onClick={handleUpdateVendor} variant="contained">
                        Update
                    </PremiumButton>
                </DialogActions>
            </Dialog>

            {/* Add Vendor Popup */}
            <Dialog open={showAddVendorPopup} onClose={handleCloseAddVendorPopup} maxWidth="sm" fullWidth>
                <DialogContent>
                    <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
                        Add New News
                    </Typography>
                    <StyledTextField
                        label="Title"
                        fullWidth
                        value={newVendor.title}
                        onChange={(e) => setNewVendor({ ...newVendor, title: e.target.value })}
                        sx={{ mb: 2 }}
                    />
                    <StyledTextField
                        label="Video Link"
                        fullWidth
                        value={newVendor.link}
                        onChange={(e) => setNewVendor({ ...newVendor, link: e.target.value })}
                        sx={{ mb: 2 }}
                    />

                    <Box sx={{ mb: 2 }}>
                        <input
                            accept="image/*"
                            style={{ display: 'none' }}
                            id="upload-image"
                            type="file"
                            onChange={handleImageChange}
                        />
                        <label htmlFor="upload-image">
                            <Button variant="outlined" color="primary" component="span">
                                Upload Image
                            </Button>
                        </label>
                    </Box>
                    {newVendor.image_data && (

                        <DetailImage
                            sx={{ width: '50%', marginLeft: '25%' }}
                            src={`data:image/jpeg;base64,${newVendor.image_data}`}
                            alt="Vendor Preview"
                        />
                    )}
                    <StyledTextField
                        select
                        label="News Type"
                        fullWidth
                        value={newVendor.type}
                        onChange={(e) => setNewVendor({ ...newVendor, type: e.target.value })}
                        sx={{ mb: 2 }}
                    >
                         
                            <MenuItem value="2">Latest</MenuItem>
                            <MenuItem value="3">Normal</MenuItem>
                    </StyledTextField>
                    <StyledTextField
                        select
                        label="Status"
                        fullWidth
                        value={newVendor.status}
                        onChange={(e) => setNewVendor({ ...newVendor, status: e.target.value })}
                    >
                        <MenuItem value="enabled">Enabled</MenuItem>
                        <MenuItem value="disabled">Disabled</MenuItem>
                    </StyledTextField>
                </DialogContent>
                <DialogActions>
                    <PremiumButton onClick={handleCloseAddVendorPopup} variant="outlined">
                        Cancel
                    </PremiumButton>
                    <PremiumButton onClick={handleSubmitNewVendor} variant="contained">
                        Submit
                    </PremiumButton>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Vendormgt;
